.signup {
  background: #1A2038;

  .signup-card {
    max-width: 800px;
    border-radius: 12px !important;
  }
}

.firebaseui-container {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}