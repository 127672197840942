#root,
body,
html {
  width: 100%;
  height: calc(100vh - 160px);
  position: relative;
}

div,
a {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
.card-title {
  color: $text-body;
}


.layout1,
.MuiPaper-root,
.MuiTableCell-body {
  color: $text-body !important;
}

button:focus {
  outline: initial;
}