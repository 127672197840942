.circular-image-small {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.card {
  transition: all 0.3s ease;
  &:hover {
    box-shadow: $elevation-z12;
  }
}
.card-title {
  font-size: 1rem;
  font-weight: 600;
}
.card-subtitle {
  font-size: 0.875rem;
  color: $muted;
}

.VictoryContainer {
  svg {
    height: 100% !important;
  }
}

.box-shadow-none {
  box-shadow: none !important;
}

.circle-44 {
  height: 44px !important;
  width: 44px !important;
}

.circle-32 {
  height: 32px !important;
  min-height: 32px !important;
  width: 32px !important;

  .MuiFab-root {
    min-height: 32px !important;
  }
  .MuiIcon-root {
    font-size: 13px !important;
  }
}


.highlight-js {
  pre {
    white-space: pre-line;
  }
}
