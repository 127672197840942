.sidenav,
.topbar,
.content-wrap {
  transition: all 0.3s ease;
}


.layout1 {
  height: 100%;
  .content-wrap {
    flex: 1 1 auto;
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    .topbar {
      top: 0;
      z-index: 96;
      position: fixed;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.95) 44%,
        rgba(247, 247, 247, 0.4) 50%,
        rgba(255, 255, 255, 0)
      );
      .topbar-hold {
        position: relative;
        // box-shadow: $elevation-z8;
        // height: $topbar-height;
        border-radius: inherit;
        padding: 0 1rem;
        // z-index: 15;
      }
    }
    .scrollable-content {
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
      .content {
        position: relative;
        height: 100%;
      }
    }
  }


}
