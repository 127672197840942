.ql-container {
  p {
    font-size: 15px
  }
  .ql-tooltip {
    z-index: 1;
  }
}

.ql-snow.ql-toolbar {
  background-color: rgba(0, 0, 0, 0.08);
  .ql-size {
    font-size: 15px
  }

}

.ql-editor {
  p, ul, ol {
    font-size: var(--quill-font-size)
  }
  &.ql-blank {
    font-size: var(--quill-font-size)
  }
  a {
    text-decoration: underline;
    color: #396DC6;
    cursor: pointer;
  }
}

.ql-align-center {
  img {
    margin-left: auto;
    margin-right: auto;
  }
}

.ql-align-right {
  img {
    margin-left: auto;
  }
}

.ql-clipboard {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  opacity: 0;
}

.ql-emoji:after {
  content: "🙂";
}

.drag-copy ~ div {
  transform: none !important;
}

.ap-footer-algolia svg {
  display: inherit;
}
.ap-footer-osm svg {
  display: inherit;
}
