progress {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 35px;
  display: block;
  height: 15px;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
progress::-webkit-progress-bar {
  background-color: rgba(0,0,0,0.125);
}
progress::-webkit-progress-value {
}
progress::-moz-progress-bar {
}
progress::-ms-fill {
  border: none;
}

.progress-blue::-webkit-progress-value {
  background-color: #0091EA;
}
.progress-blue::-moz-progress-bar {
  background-color: #0091EA;
}
.progress-blue::-ms-fill {
  background-color: #0091EA;
}

.progress-orange::-webkit-progress-value {
  background-color: #f7b801;
}
.progress-orange::-moz-progress-bar {
  background-color: #f7b801;
}
.progress-orange::-ms-fill {
  background-color: #f7b801;
}