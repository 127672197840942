.layout-full {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.layout-contained {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.layout-contained-lg {
  max-width: 1200px;
  margin: auto;
  width: 100%;
}

.layout-boxed {
  max-width: $contained-layout-width;
  margin: auto;
  box-shadow: $elevation-z12;
  background: $white;
  @media screen and (max-width: 767px) {
    max-width: 100%;
    box-shadow: none;
  }
}
