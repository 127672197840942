body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: auto !important;
  font-size: 16px;
  position: relative;
}

.algolia-places {
  padding-right: 50px;
}

.uppy-Dashboard-inner {
  max-height: 330px !important;
}